import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layouts'
import styled from 'styled-components'
import { InnerPageHero } from '../components/heroes';
import * as ds from '../uiLibrary'
import HeadMeta from '../components/HeadMeta'

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
function chunkArray(myArray, chunk_size) {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

const StyledCard = styled(ds.Card)`
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  opacity: 0.7;
  order: ${ props => props.order};
  @media screen and (min-width: ${ds.mq.medium}){
    height: auto;
  }
  @media screen and (min-width: ${ds.mq.large}){
    height: 150px;
  }
`

const VividVisionPage = ({ data }) => {
  const page = data.allVividVisionJson.edges[0].node
  const chunkedFeatures = chunkArray(page.features, 2)

  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'Vivid Vision - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <InnerPageHero url={page.heroBackground}>
        <ds.FixedWidthRow pb={0}>
          <ds.Column span={12}>
            <ds.Headline fontSize={[ds.typeScale.t6, ds.typeScale.t8]}>{page.heroHeading}</ds.Headline>
            <ds.H2 fontWeight={200} color={ds.colors.lightGray}>
              <ReactMarkdown
                source={page.heroBody}
              />
            </ds.H2>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.midnight}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.H2>
              {page.introHeading}
            </ds.H2>
          </ds.Column>
          <ds.Column span={12}>
            <ReactMarkdown
              source={page.introBody}
              renderers={{
                paragraph: props => <ds.LeadText {...props} />
              }}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg='dusk'>
        <ds.FixedWidthRow pb={[ds.spacing.s2, ds.spacing.s3, ds.spacing.s5]}>
          <ds.Column span={4} pl={3}>
            <ds.Headline>
              {page.essenceHeading.toUpperCase()}
            </ds.Headline>
          </ds.Column>
          <ds.Column vc span={8}>
            <ds.H4>
              {page.essenceBody}
            </ds.H4>
          </ds.Column>
        </ds.FixedWidthRow>
        {page.essenceSections.map((section, idx, sections) => (
          <React.Fragment key={idx}>
            <ds.FixedWidthRow py={[ds.spacing.s3, ds.spacing.s3, ds.spacing.s5]} alignItems={"center"} >
              <ds.Column span={3} display={"flex"}>
                <ds.CenteredImg src={section.image} />
              </ds.Column>
              <ds.Column span={4}>
                <ds.H2>
                  {section.heading}
                </ds.H2>
                <ds.PStyles>
                  {section.body}
                </ds.PStyles>
              </ds.Column>
            </ds.FixedWidthRow>
            <ds.FixedWidthRow display={"flex"} alignItems={"center"} py={idx + 1 !== sections.length ? 0 : null} pt={idx + 1 === sections.length ? 0 : null} height={"2rem"} flexDirection={["column", "column", "row"]}>
              {section.cards.map((card, idx) => (
                <ds.Column span={12} key={idx}>
                  <StyledCard width={"70%"} height={"160px"}>
                    <ReactMarkdown
                      source={card.body}
                      renderers={{
                        parargraph: props => <ds.PStyles color={"black"} />
                      }}
                    />
                  </StyledCard>
                </ds.Column>
              ))}
            </ds.FixedWidthRow>
          </React.Fragment>
        ))}
      </ds.Background>
      <ds.Background bg={ds.colors.midnight}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.Headline>
              {page.detailHeading.toUpperCase()}
            </ds.Headline>
          </ds.Column>
          <ds.Column>
            <ReactMarkdown
              source={page.detailBody}
              renderers={{
                paragraph: props => <ds.LeadText {...props} />
              }}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg='dusk'>
        <ds.FixedWidthRow pb={1}>
          <ds.Column span={12}>
            <ds.Headline mb={1}>
              {page.featuresPrimaryHeading.toUpperCase()}
            </ds.Headline>
          </ds.Column>
          <ds.Column>
            <ds.LeadText>
              {page.featuresPrimaryBody}
            </ds.LeadText>
            <ds.H2 mt={4}>
              {page.featuresSecondaryHeading}
            </ds.H2>
            <ds.LeadText>
              {page.featuresSecondaryBody}
            </ds.LeadText>
          </ds.Column>
        </ds.FixedWidthRow>
        {chunkedFeatures.map((group, idx, groups) => (
          <ds.FixedWidthRow pb={idx + 1 !== groups.length ? 1 : null} key={idx}>
            {group.map((feature, idx) => (
              <React.Fragment key={idx}>
                <ds.Column span={2} display={["none", "block"]}>
                  <ds.CenteredImg src={feature.image} />
                </ds.Column>
                <ds.Column span={4} mr={idx === 0 ? 2 : null}>
                  <ds.P textAlign={["center", "left"]}>
                    {feature.body}
                  </ds.P>
                </ds.Column>
              </React.Fragment>
            ))}
          </ds.FixedWidthRow>
        ))}
      </ds.Background>
      <InnerPageHero url={page.experienceBackground} pt={0}>
        <ds.FixedWidthRow py={0}>
          <ds.Column span={12}>
            <ds.H2 mt={4}>
              {page.experienceHeading.toUpperCase()}
            </ds.H2>
          </ds.Column>
        </ds.FixedWidthRow>
        <ds.FixedWidthRow pt={0}>
          <ds.Column span={12}>
            <ReactMarkdown
              source={page.experienceBody}
              renderers={{
                paragraph: props => <ds.LeadText color={ds.colors.lightGray} {...props} />
              }}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg='dusk'>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.Headline>
              {page.charityHeading.toUpperCase()}
            </ds.Headline>
            <ReactMarkdown
              source={page.charityBody}
              renderers={{
                paragraph: props => <ds.LeadText {...props} />
              }}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.midnight}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.Headline>
              {page.storyHeading.toUpperCase()}
            </ds.Headline>
            <ReactMarkdown
              source={page.storyBody}
              renderers={{
                paragraph: props => <ds.LeadText {...props} />
              }}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const query = graphql`
  query {
    allVividVisionJson {
      edges {
        node {
          heroHeading
          heroBody
          heroBackground
          introHeading
          introBody
          essenceHeading
          essenceBody
          essenceSections {
            heading
            body
            image
            cards {
              body
            }
          }
          detailHeading
          detailBody
          featuresPrimaryHeading
          featuresPrimaryBody
          featuresSecondaryHeading
          featuresSecondaryBody
          features {
            body
            image
          }
          experienceHeading
          experienceBody
          experienceBackground
          charityHeading
          charityBody
          storyHeading
          storyBody
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
`

export default VividVisionPage